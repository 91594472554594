// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BuildOutlined,
  BarChartOutlined,
  FormOutlined,
  LineChartOutlined,
  DeploymentUnitOutlined,
  DashboardOutlined,
  GoldOutlined,
  MonitorOutlined,
  ForkOutlined
} from '@ant-design/icons';

const icons = {
  dashboard: DashboardOutlined,
  components: GoldOutlined,
  LineChartOutlined,
  DeploymentUnitOutlined,
  FormOutlined,
  BarChartOutlined,
  MonitorOutlined,
  ForkOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const pricingSolution = {
  id: 'pricing-solution',
  title: <FormattedMessage id="pricing-solution" />,
  type: 'group',
  children: [
    {
      id: 'rate-increases',
      title: <FormattedMessage id="rate-increases" />,
      type: 'item',
      url: '/rate-increases',
      icon: icons.LineChartOutlined
    },
    {
      id: 'GRImockup',
      title: <FormattedMessage id="GRImockup" />,
      type: 'item',
      url: '/GRImockup',
      icon: icons.LineChartOutlined
    },
    {
      id: 'network-optimization',
      title: <FormattedMessage id="network-optimization" />,
      type: 'item',
      url: '/projects',
      icon: BuildOutlined
    },
    {
      id: 'shipment-reconciliation',
      title: <FormattedMessage id="shipment-reconciliation" />,
      type: 'item',
      url: '/reconciliation',
      icon: ForkOutlined
    },
    {
      id: 'KPI-monitoring',
      title: <FormattedMessage id="KPI-monitoring" />,
      type: 'item',
      url: '/monitoring',
      icon: MonitorOutlined
    }
  ]
};

export default pricingSolution;
